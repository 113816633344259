<template>
	<Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" >
    <MDBCardBody>
	<MDBContainer fluid class="mt-3">
    <transition name="fade">
      <PageLoading v-if="PageLoading"></PageLoading>
    </transition>
    <MDBRow>
      <MDBCol class="mb-1">
        <input type="date" v-model="start" class="form-control" format="YYYY-MM-DD">
      </MDBCol>
      <MDBCol class="mb-1">
        <input type="date" v-model="end" class="form-control" format="YYYY-MM-DD">
      </MDBCol>
      <MDBCol class="mb-3">
        <MDBBtn color="secondary" @click="setDate(-7,0)">{{ $t("COMMON.BEFORE7DAYS") }}</MDBBtn>  
        <MDBBtn color="secondary" @click="setDate(-30,0)">{{ $t("COMMON.BEFORE30DAYS") }}</MDBBtn>  
        <MDBBtn color="secondary" @click="setMonth(-1)">{{ $t("COMMON.BEFOREMONTH") }}</MDBBtn>  
        <MDBBtn color="secondary" @click="setMonth(0)">{{ $t("COMMON.THISMONTH") }}</MDBBtn>  
      </MDBCol>
      <MDBCol class="mb-2 text-end">
        <MDBBtn color="info" @click="GetTable">{{ $t("COMMON.SEARCH") }}</MDBBtn>  
        <MDBBtn color="warning" @click="Export">{{ $t("COMMON.EXPORT") }}</MDBBtn>  
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol md="2">
        <MDBInput :label="$t('COMMON.ORDERNUMBER')" :placeholder="$t('COMMON.ORDERNUMBER')" v-model="order" />
      </MDBCol>
      <MDBCol md="2">
        <MDBInput :label="$t('COMMON.MEMBEREMAIL')" :placeholder="$t('COMMON.MEMBEREMAIL')" v-model="member" />
      </MDBCol>
      <!-- <MDBCol md="2">
        <MDBInput :label="$t('COMMON.DEALEREMAIL')" :placeholder="$t('COMMON.DEALEREMAIL')" v-model="dealer" />
      </MDBCol> -->
      <MDBCol md="6">
      </MDBCol>
    </MDBRow>
		<DataTable
			:header="headers"
			:tabledata="desserts"
      :tfoot="tfoot"
			v-model:total="total"
			v-model:options="options"
			v-model:loading="loading"
		>
      <template v-slot:type="{ item }">
        {{ $t(`ORDER.TYPE_${item.type}`) }}
      </template>
      <template v-slot:refer="{ item }">
        {{ (item.member.refer)?(item.member.refer.last_name + item.member.refer.first_name):"" }}
      </template>
      <template v-slot:dealer="{ item }">
        <tr v-for="(data,k) in item.refer" :key="k" scope="row" class="dealer_tr">
          <td>{{ (data.dealer)?(data.dealer.last_name + data.dealer.first_name):"" }}</td>
        </tr>
        
      </template>
      <template v-slot:member="{ item }">
        {{ item.member.last_name + item.member.first_name }}
      </template>
      <template v-slot:company="{ item }">
        {{ item.member.unit??"" }}
      </template>
      <template v-slot:promo="{ item }">
        {{ (item.promo)?item.promo.code:"" }}
      </template>
      <template v-slot:order_amount="{ item }">
        {{ item.order.amount }}
      </template>
      <template v-slot:amount="{ item }">
        {{ item.amount - item.promo_amount }}
      </template>
      <template v-slot:percentage="{ item }">
        <tr v-for="(data,k) in item.refer" :key="k" scope="row" class="dealer_tr">
          <td>{{ data.percentage }}</td>
        </tr>
      </template>
      <template v-slot:my_percentage="{ item }">
        {{ 100 - item.dealer_percentage }}
      </template>
      <template v-slot:product_type="{ item }">
        {{ $t(`PRODUCTTYPE.TYPE_${item.product_type}`) }}
      </template>
      <template v-slot:next_at="{ item }">
        {{ (item.payment_able==0)?"":((!!item.payment_at)?Common.TimeFormat(NextMonth(item.payment_at,((item.type=="bank")?item.payment_able:1))):"") }}
      </template>
      <template v-slot:payment_at="{ item }">
        {{ (item.payment_able==0)?"":((!!item.payment_at)?Common.TimeFormat(NextMonth(item.payment_at,((item.type=="bank")?item.payment_able:1))):"") }}
      </template>
      <template v-slot:calamount="{ item }">
        {{ item.payment_count * item.really_amount }}
      </template>
      <template v-slot:refer_paid_amount="{ item }">
        <tr v-for="(data,k) in item.refer" :key="k" scope="row" class="dealer_tr">
          <td>{{data.origin_amount}}</td>
        </tr>
      </template>
      <template v-slot:refer_amount="{ item }">
        <tr v-for="(data,k) in item.refer" :key="k" scope="row" class="dealer_tr">
          <td>{{data.amount}}</td>
        </tr>
      </template>
      <template v-slot:my_profit="{ item }">
        {{ (item.order_really_total - ( Math.floor(item.order_really_total * item.dealer_percentage)/100)).toFixed(2) }}
      </template>
      <template v-slot:my_really_profit="{ item }">
        {{ item.really_amount - ((item.refer.length>0)?item.refer.map(item=>item.amount).reduce((total,num)=> parseFloat(total) + parseFloat(num) ):0) }}
      </template>
			<!-- 時間轉換 -->
      <template v-slot:created_at="{ item }">
        {{ Common.TimeFormat(item.created_at) }}
      </template>
      <template v-slot:tfoot="{ item }">
        <tr>
          <td colspan="24"></td>
          <td>{{ $t("COMMON.TOTAL") }}</td>
          <td>NTD</td>
          <td>{{ (item.really_total - item.refer_total).toFixed(2) }}</td>
        </tr>
      </template>
		</DataTable>
	</MDBContainer>
  </MDBCardBody>
  </MDBCard>
</template>

<style scope>
  .dealer_tr {
    border-bottom:1px solid #000;
  }
</style>

<script>
import { MDBContainer, MDBCard, MDBCardBody, MDBBtn, MDBRow, MDBCol, MDBInput } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import PageLoading from '@/components/Elements/PageLoading';
import { ref, reactive } from "vue";
import { useI18n } from 'vue-i18n';
import JwtService from "@/core/services/jwt.service";

export default {
  name: "SaleReport",
  components: {
		DataTable,
		MDBContainer,
		Breadcrumbs,
    MDBCard,
    MDBCardBody,
    PageLoading,
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBInput,
  },
  setup() {
    const i18n = useI18n();
    const PageLoading = ref(false);
    const loading = ref(true);
    // const exportLoading = ref(false);
    const total = ref(0);
    const Common = CommonService;
    const desserts = ref([]);
    const tfoot = ref([]);
    const date = new Date();
    const today = `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2,0)}-${date.getDate().toString().padStart(2,0)}`;
    const start = ref(today);
    const end = ref(today);
    const member = ref("");
    const dealer = ref("");
    const order = ref("");

    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["order.created_at"],
      sortDesc: ['desc'],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,

    });

    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no",
        width:50
      },
      { text: i18n.t("COMMON.ORDERTIME"), value: "created_at",width:200 },
      { text: "訂單編號", value: "order",width:112 },
      { text: "訂閱次數", value: "payment_able",width:112 },
      { text: "訂閱到期日", value: "payment_at",width:112 },
      { text: "下次付款日", value: "next_at",width:112 },
      { text: "付款累積金額", value: "calamount",width:112 },
      { text: "已付款次數", value: "payment_count",width:112 },
      { text: "產品類型", value: "product_type",width:112 },
      { text: "商品名稱", value: "product.name",width:112 },
      { text: "會員", value: "member",width:112 },
      { text: "所屬經銷商", value: "refer",width:112 },
      { text: "公司/學校名稱", value: "company" },
      { text: "優惠碼", value: "promo",width:112 },
      { text: "優惠代碼折抵", value: "order_promo_total",width:112 },
      { text: "訂單幣別", value: "currency",width:112 },
      { text: "訂單合計", value: "order_really_total",width:112 },
      { text: "訂單合計(NTD)", value: "really_amount",width:112 },
      { text: "經銷商", value: "dealer",width:112 },
      { text: "經銷利潤(%)", value: "percentage",width:112 },
      { text: "幣別", value: "currency",width:112 },
      { text: "經銷利潤", value: "refer_paid_amount",width:112 },
      { text: "經銷利潤(NTD)", value: "refer_amount",width:112 },
      { text: "我的利潤(%)", value: "my_percentage",width:112 },
      { text: "幣別", value: "currency",width:112 },
      { text: "仁安利潤", value: "my_profit",width:112 },
      { text: "我的利潤(NTD)", value: "my_really_profit",width:112 },

    ]);

    const GetTable = function() {
      return new Promise(resolve => {
        loading.value = true;
        ApiService.query(
          "/Admin/v1/SaleReport",
          {
            params: Object.assign(CommonService.SortHandleObject(options),{
              start: `${start.value} 00:00:00`,
              end: `${end.value} 23:59:59`,
              member: member.value,
              dealer: dealer.value,
              order: order.value,
            })
          }
        ).then(response => {
          if (response.status == 200) {
            desserts.value = CommonService.DataNoHandle(
              response.data.data.data.data,
              options.page,
              options.itemsPerPage
            );
            total.value = response.data.data.data.total;
            tfoot.value = response.data.data.total;
            resolve(response);
          }
        });
      });
    };

    const Export = function() {
      var obj = {
        page: 1,
        limit: -1,
        start: `${start.value} 00:00:00`,
        end: `${end.value} 23:59:59`,
        member: member.value,
        dealer: dealer.value,
        order: order.value,
        token: JwtService.getToken(),
      };
      window.open(`${process.env.VUE_APP_API_URL}/Admin/v1/SaleReport/Export?${Object.entries(obj).map(([k, v]) => `${k}=${encodeURIComponent(v)}`).join("&")}`)
    };

    const getDate = function(index){
      var date = new Date(); //当前日期
      var newDate = new Date();
      newDate.setDate(date.getDate() + index);//官方文档上虽然说setDate参数是1-31,其实是可以设置负数的
      var time = newDate.getFullYear()+"-"+(newDate.getMonth()+1).toString().padStart(2,0)+"-"+newDate.getDate().toString().padStart(2,0);
      return time;
    }

    const setDate = function(startIndex,endIndex) {
      start.value = getDate(startIndex);
      end.value = getDate(endIndex);
    }

    const setMonth = function(index) {
      var date = new Date(); //当前日期
      var nowMonth = date.getMonth() + 1 + index;
      var nowDate = new Date(((new Date(`${date.getFullYear()}/${nowMonth}/1`)).getTime()));
      var nextMonth = date.getMonth() + 2 + index;
      var nextDate = new Date(((new Date(`${date.getFullYear()}/${nextMonth}/1`)).getTime() - 86400000));
      start.value = nowDate.getFullYear()+"-"+(nowDate.getMonth()+1).toString().padStart(2,0)+"-01";
      end.value = nextDate.getFullYear()+"-"+(nextDate.getMonth()+1).toString().padStart(2,0)+"-"+nextDate.getDate().toString().padStart(2,0);
    }

    const NextMonth = function(date,month) {
      let d = new Date(date);
      d.setMonth(d.getMonth()+month);
      return d.getTime();
    }

		return {
      PageLoading,
      loading,
      total,
      Common,
      options,
      headers,
      desserts,
      GetTable,
      tfoot,
      start,
      end,
      member,
      dealer,
      order,
      setDate,
      setMonth,
      Export,
      NextMonth,
    };
  },
  watch: {
		options: {
      handler() {
        this.GetTable();
      },
      deep: true
    },
  },
  mounted() {
    this.GetTable();
  }
};
</script>